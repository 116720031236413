import axios from 'axios';

const token = localStorage.getItem('accessToken');
let code = ""
let storeReference = ""
let ticker = ""

if (localStorage.getItem('storeReference') === "" || localStorage.getItem('storeReference') === null) {
    storeReference = localStorage.setItem("storeReference", 0)
} else {
    storeReference = localStorage.getItem('storeReference')
}

if (localStorage.getItem('langCode') === "" || localStorage.getItem('langCode') === null) {
    code = "en"
} else {
    code = localStorage.getItem('langCode')
}

if ( localStorage.getItem('instrumentTicker') === "" || localStorage.getItem('instrumentTicker') === null ) {
    ticker = "DNEEUR"
} else {
    ticker = localStorage.getItem('instrumentTicker')
}

const apiPlatform = axios.create({
    baseURL: process.env.VUE_APP_PLATFORM_API,
    headers: { 'Authorization': `bearer ${token}`, 'content-language': code, 'store-reference': storeReference, 'Instrument-Ticker': ticker, 'X-Client-Identifier': "DnePay" }
});

export default apiPlatform;