<template>
    <div>
        <div class="login" :class="{open: loginActive}">
            <!-- LOGIN -->
            <div class="sign-in" v-if="!$store.state.statusLogin">
                <div class="open-box-login" @click='loginActive = true'></div>
                <span class="photo"></span>
                <!-- <span class="no-logged">{{ getMyLanguage("Login", "login.sign-in") }}</span> -->
            </div>

            <div class="box-login" v-if="loginActive">
                <div class="close-box-login" v-if="loginActive" @click="closeBoxLogin()"></div>

                <h4 class="title-secondary" v-if="!loginRemember">
                    {{ getMyLanguage("Login", "login.access-account") }}
                </h4>

                <h4 class="title-secondary" v-if="loginRemember">
                    {{ getMyLanguage("Login", "login.remember-password") }}
                </h4>

                <div class="LoginOne" v-if="loginOne">
                    <input type="email" class="form formEmailLogin" imputemode="email" id="emailLogin"
                        @keyup.enter="loginPlatform()"
                        :placeholder='getMyLanguage("Login","login.placeholder.login")'
                        v-model="sign.username">
                    
                    <div class="enterPass">
                        <input type="password" class="form formPassLogin"
                            v-if="!showPass"
                            @keyup.enter="loginPlatform()"
                            :placeholder='getMyLanguage("Login","login.placeholder.password")'
                            v-model="sign.password">

                        <input type="text" class="form formPassLogin"
                            v-if="showPass"
                            @keyup.enter="loginPlatform()"
                            :placeholder='getMyLanguage("Login","login.placeholder.password")'
                            v-model="sign.password">

                        <img src="@/assets/images/icons/no-view.svg" class="eyePass" v-if="!showPass" @click="showPass = true" />
                        <img src="@/assets/images/icons/view.svg" class="eyePass" v-if="showPass" @click="showPass = false" />
                    </div>

                    <div class="btn-remember-password" @click="loginResetPass">
                        {{ getMyLanguage("Login", "login.remember-password") }}
                    </div>
                    
                    <div class="btn-primary btn-disabled" v-if="sign.username.length <= 5 || sign.password.length <= 7">
                        {{ getMyLanguage("Login","login.login") }}
                    </div>

                    <div class="btn-primary" @click="loginPlatform()" v-if="!stepLogin.load && sign.username.length > 5 && sign.password.length > 7">
                        {{ getMyLanguage("Login","login.login") }}
                    </div>

                    <div class="btn-primary btn-loading" v-if="stepLogin.load"></div>
                    <!-- <div class="btn-primary nextLogin" @click="loginSMS">Continue</div> -->

                    <div class="register" v-if="dataPhaseCurrent.phase_type != 'Private'">
                        <span class="or"><small>{{ getMyLanguage("Login","login.or") }}</small></span>
                        <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/account/register`" target="_blank" class="btn-primary btn-register">
                            {{ getMyLanguage("Login", "login.register-now") }}
                        </a>
                    </div>
                </div>

                <!-- <div class="loginTwo" v-if="loginTwo">
                    <input type="text" class="form" id="smsCode" placeholder="SMS Code">
                    <button type="Submit" class="btn-primary">Login</button>
                </div> -->
                
                <div class="loginRemember" v-if="loginRemember && (!rememberSend || !rememberError)">
                    <input type="text" class="form formEmailLogin" id="emailRemember"
                        v-model="emailRemember.email"
                        :placeholder='getMyLanguage("Login","login.remember-email-registered")'>

                    <button class="btn-primary"
                        @click="rememberPass()"
                        v-if="!emailRemember.load">
                        
                        {{ getMyLanguage("Login", "login.remember") }}
                    </button>

                    <div class="btn-primary load" v-if="emailRemember.load"></div>
                </div>

                <div class="rememberPass rememberSuccess" v-if="!loginRemember && rememberSend">
                    <p>{{getMyLanguage("Login","login.remember-password.success")}}</p>
                </div>
                
                <div class="rememberPass rememberError" v-if="loginRemember && rememberError">
                    <p>{{getMyLanguage("Login","login.remember-password.error")}}</p>
                </div>
            </div>
            <!-- END LOGIN -->
            
            
            <div class="sign-in" v-if="$store.state.statusLogin">
                <div class="open-box-login" @click='logoutActive = true'></div>
                <div class="close-box-login" v-if="logoutActive" @click="closeBoxLogin()"></div>
                <span class="photo"><img src="@/assets/images/users/user.svg"></span>
                <!-- <span class="no-logged">{{$store.state.profile.profile_full_name}}</span> -->
            </div>

            
            <div class="box-login box-logged" :class="{boxMerchant: isMerchant}" v-if="logoutActive">
                <div class="close-box-login" v-if="logoutActive" @click="closeBoxLogin()"></div>

                <div class="user-loginOne">
                    <div class="user">
                        <div class="photo-user">
                            <div></div>
                        </div>

                        <h4 class="name-user">
                            {{$store.state.profile.profile_full_name}}
                        </h4>
                    </div>
                    
                    <div class="LoginOne" v-if="loginOne">
                        <a :href="`/${flagSelected}/client-space/wallets`" class="btn-box-login btn-box-wallets">
                            <!-- {{getMyLanguage("ClientSpace","wallets.title")}} -->
                            Carteiras
                        </a>

                        <a :href="`/${flagSelected}/client-space/transactions`" class="btn-box-login btn-box-transactions">
                            <!-- {{getMyLanguage("ClientSpace","transactions.title")}} -->
                            Transações
                        </a>

                        <div class="btn-box-login btn-box-purchase" @click="purchaseToken()">
                            Comprar DNE Token
                        </div>

                        <div class="btn-box-login btn-box-logout" @click="logoutMarketPlace()">
                            <span>{{ getMyLanguage("Login","logout") }}</span>
                        </div>
                    </div>
                </div>

                <div class="my-business" v-if="dataMerchant.length">
                    <h3><span>{{getMyLanguage("Login",'my-business.title')}}</span></h3>

                    <div class="store-list">
                        <div class="store"
                            @click="changeStore(storeMerchant.store.reference, storeMerchant.store.name, storeMerchant.company_reference, storeMerchant.company_account_id, storeMerchant.company_id)"
                            v-for="(storeMerchant, index) in dataMerchant"
                            :key="index">

                            <div class="store-name">
                                <div class="acronym">{{ acronym(storeMerchant.store.name) }}</div>
                                <div class="name">{{storeMerchant.store.name}}</div>
                            </div>
                        </div>
                    </div>
                    
                    <div @click="lnkAddCompany()" class="add-store">
                        <span>
                            {{getMyLanguage("Login","my-business.add-company")}}
                        </span>
                    </div>
                </div>

                <div class="my-business" v-if="!dataMerchant.length">
                    <!-- <BecomeSeller :isMerchant="dataSource" :dataOrigin="origin" /> -->

                    <div @click="lnkAddCompany()" class="add-store first">
                        <span>
                            {{getMyLanguage("Login","my-business.add-company")}}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="overlay"
            v-if="loginActive || logoutActive"
            @click="loginActive = false, loginRemember = false, loginOne = true, rememberSend = false"></div>
    </div>
</template>


<script>
    const $ = require('jquery')
    window.$ = $

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMerchant from '@/config/apiMerchant.js'
    import apiInternational from '@/config/apiInternational.js'
    import apiPlatform from '@/config/apiPlatform.js'

    import bus from '@/eventBus';

    export default{
        props: {
            dataSource: Boolean
        },

        data(){
            return{
                flagSelected: "en",
                
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                VUE_APP_MARKET_URL: process.env.VUE_APP_MARKET_URL,
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
                VUE_APP_PAY_URL: process.env.VUE_APP_PAY_URL,
                VUE_APP_EXCHANGE_URL: process.env.VUE_APP_EXCHANGE_URL,

                tokenAuth: localStorage.getItem('accessToken'),

                origin: "Login",
                
                loginActive: false,
                logoutActive: false,
                
                loginOne: true,
                loginTwo: false,
                loginRemember: false,
                showPass: false,
                
                emailRemember: {
                    load: false,
                    email: ''
                },
                rememberSend: false,
                rememberError: false,
                
                dataLogin: '',
                dataAccount: [],
                
                sign: {
                    "username": "",
                    "password": ""
                },
                
                stepLogin: {
                    "load": false
                },
                
                validate: {
                    "tooltipUsername": false,
                    "tooltipPassword": false
                },
                
                statusUserLogin: {
                    logged: this.$store.state.statusLogin
                },

                loginSource: "",

                hasToken: "",
                token: "",
                isMerchant: false,

                dataMerchant: {},
                storeReferenceActive: 0,

                dataPhaseCurrent: "",

                settingsLogin: {
                    source: "login",
                    code: "",
                    language: "",
                    flagLanguage: "",
                    shippingCountry: "",
                    shippingFlag: "",
                    instrumentTicker: "",
                    tickerShort: ""
                }
            }
        },

        async mounted(){
            this.checkToken()

            this.storeReferenceActive = localStorage.getItem("storeReference")

            const checkUrl = String(window.location.pathname).split("/");
            this.urlSplit = checkUrl
            
            if ( this.urlSplit[2] == "merchant" && this.urlSplit[3] == "products" && this.urlSplit.length == 4 ){
                this.urlMerchant = true
            }
            
            const resumePhaseCurrent = await apiInternational.get('/api/v2/ico/phase/current')
            this.dataPhaseCurrent = resumePhaseCurrent.data
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            closeBoxLogin(){
                this.loginActive = false
                this.loginRemember = false
                this.loginOne = true
                this.rememberSend = false

                this.logoutActive = false

                bus.emit('close-sidebar');
            },

            loginPlatform(){
                const loginPlatform = this.sign;
                this.stepLogin.load = true

                apiPlatform.post("/api/v1/platform/account/login", loginPlatform)
                .then(response => {
                    localStorage.setItem('accessToken', response.data.accessToken);

                    this.settingsLogin = response.data.profile

                    this.flagSelected = this.settingsLogin.account_language_alpha_2

                    localStorage.setItem('codeLanguage', this.settingsLogin.account_language_alpha_2)
                    localStorage.setItem('Language', this.settingsLogin.account_language_name)
                    localStorage.setItem('flagLanguage', this.settingsLogin.account_language_flag)

                    this.$store.commit('preLanguage', this.settingsLogin.account_language_alpha_2)
                    this.$store.commit('preLanguageFlag', this.settingsLogin.account_language_flag)
                    this.$store.commit('preLanguageName', this.settingsLogin.account_language_name)

                    // localStorage.setItem("shippingCountry", this.settingsLogin.profile_country_name) // alterar para país do endereço (indisponível no response)
                    // localStorage.setItem("shippingFlag", this.$store.state.allCountries.filter(ctry => ctry.Name == this.settingsLogin.profile_country_name)[0].Flag)
                    // localStorage.setItem("countryIsoAlpha3", this.settingsLogin.profile_country_iso_code_alpha_3)
                    
                    localStorage.setItem("instrumentTicker", this.settingsLogin.instrument_ticker)
                    localStorage.setItem("instrumentTickerShort", this.settingsLogin.asset_short)

                    // localStorage.setItem("shippingCountry", this.$store.state.allCountries.filter(ship => ship.IsoCode == this.settingsLogin.profile_country_iso_code)[0].Name)
                    // localStorage.setItem("shippingFlag", this.$store.state.allCountries.filter(ship => ship.IsoCode == this.settingsLogin.profile_country_iso_code)[0].Flag)
                    // localStorage.setItem("IsoCode", this.settingsLogin.profile_country_iso_code)

                    console.log(this.settingsLogin)

                    window.location.href = `/${this.flagSelected}/client-space/`
                })
                .catch(error => {
                    this.stepLogin.load = false

                    console.clear()
                    console.log(error);

                    $('.LoginOne').addClass('erroLogin');

                    setTimeout(() => {
                        $('.LoginOne').removeClass('erroLogin');
                    }, 6000);


                    if ( error.response.status == 400 ){
                        this.$toast.error(this.getMyLanguage("Login","login-password-error"));
                    } else{
                        this.$toast.error(this.getMyLanguage("Login","login-error-authentication"));
                    }
                })
            },



            // loginPlatform(){
            //     const loginPlatform = this.sign;
            //     this.stepLogin.load = true

            //     apiPlatform.post("/api/v1/platform/account/login", loginPlatform)
            //     .then(response => {
            //         localStorage.setItem('accessToken', response.data.accessToken);

            //         this.settingsLogin.code = response.data.profile.account_language_alpha_2
            //         this.settingsLogin.language = response.data.profile.account_language_name
            //         this.settingsLogin.flagLanguage = response.data.profile.account_language_flag
            //         this.settingsLogin.shippingIsoCode = response.data.profile.profile_country_iso_code
            //         this.settingsLogin.shippingCountry = response.data.profile.profile_country_name
            //         this.settingsLogin.shippingFlag = "da30c24e-acf1-44f4-f4c2-9bf003e28700"
            //         this.settingsLogin.instrumentTicker = "DNEEUR"
            //         this.settingsLogin.tickerShort = "EUR"

            //         window.location.href = `/${this.flagSelected}/client-space/`

            //         // this.$cookies.set("idAccount", response.data.profile.account_id)

            //         // setTimeout(() => {
            //         //     if ( this.loginSource == "wishlist-login" ){
            //         //         this.loginActive = false
            //         //         localStorage.setItem("showWishilist", true)
            //         //         document.location.reload(true)
            //         //     } else if ( this.loginSource == "become-seller" ){
            //         //         this.loginActive = false
            //         //         window.location.href = `/${this.flagSelected}/merchant/company/0`
            //         //     } else {
            //         //         document.location.reload(true)
            //         //     }
            //         // }, 100);
            //     })
            //     .catch(error => {
            //         this.stepLogin.load = false

            //         console.log(error);

            //         $('.LoginOne').addClass('erroLogin');

            //         setTimeout(() => {
            //             $('.LoginOne').removeClass('erroLogin');
            //         }, 6000);

            //         let errorsLogin = JSON.stringify(error.response.status)
            //         this.loginErrors = errorsLogin
            //         // console.log(errorsLogin)

            //         if ( errorsLogin == 400 ){
            //             this.$toast.error(this.getMyLanguage("Login","login-password-error"));
            //         } else{
            //             this.$toast.error(this.getMyLanguage("Login","login-error-authentication"));
            //         }

            //         setTimeout(this.$toast.clear, 6000)
            //     })
            // },

            logoutMarketPlace(){
                localStorage.removeItem('accessToken');
                localStorage.removeItem("storeReference")
                localStorage.removeItem("companyReference")
                localStorage.removeItem("companyAccountId")
                localStorage.removeItem("companyId")
                localStorage.removeItem("storeName")
                localStorage.removeItem("isMerchant")
                this.statusUserLogin.logged = false
                this.$store.commit('loginStatus', false)

                // this.$cookies.set("idAccount","00000000-0000-0000-0000-000000000000")

                document.location.href = `/${this.flagSelected}/`
            },

            loginSMS(){
                this.loginOne = false,
                this.loginTwo = true
            },

            loginResetPass(){
                this.loginRemember = true,
                this.loginOne = false,
                this.loginTwo = false
            },

            rememberPass(){
                this.emailRemember.load = true
                
                apiPlatform.post("/api/v1/platform/account/password/reset", this.emailRemember)
                .then(response => {
                    this.registerId = response.data.id;
                    this.rememberSend = true
                    this.loginRemember = false

                })
                .catch(error => {
                    this.emailRemember.load = false
                    this.rememberError = true

                    console.clear()
                    console.log(error.message);

                    // this.$toast.error(error.response.data);
                    // setTimeout(this.$toast.clear, 6000)
                })
            },

            checkToken(){
                this.hasToken = localStorage.getItem("accessToken")

                if ( this.hasToken ){
                    var base64Url = localStorage.getItem("accessToken").split('.')[1];
                    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    }).join(''));

                    this.token = JSON.parse(jsonPayload)

                    if ( this.token.permissions.filter(access => access == "dne:market:merchant").length ){
                        // this.isMerchant = true
                        this.loadListStore()
                    }
                }
            },

            async loadListStore(){
                await apiMerchant.get('/api/v1/market/store/list')
                .then(response => {
                    this.dataMerchant = response.data

                    let storeREF = localStorage.getItem("storeReference")
                    let companyREF = localStorage.getItem("companyReference")

                    if ( storeREF == "0" && !companyREF ){
                        localStorage.setItem("storeReference", this.dataMerchant[0].store.reference)
                        localStorage.setItem("storeName", this.dataMerchant[0].store.name)
                        localStorage.setItem("companyReference", this.dataMerchant[0].company_reference)
                        localStorage.setItem("companyAccountId", this.dataMerchant[0].company_account_id)
                        localStorage.setItem("companyId", this.dataMerchant[0].company_id)

                    } else if ( storeREF == "0" && companyREF ) {
                        localStorage.setItem("storeReference", this.dataMerchant.filter(cr => cr.company_reference == companyREF)[0].store.reference)
                    }

                    if ( this.dataMerchant.length ){
                        localStorage.setItem("isMerchant", true)
                    } else {
                        localStorage.removeItem("isMerchant")
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            },

            acronym(nameStore) {
                const acronym = nameStore.split(' ');
                const firstLetter = acronym[0].charAt(0);
                const secondLetter = acronym[1]?.charAt(0) || '';
                return firstLetter + secondLetter;
            },

            changeStore(ref, name, company, accountId, companyId){
                localStorage.setItem("storeReference", ref)
                this.storeReferenceActive = localStorage.getItem("storeReference")
                localStorage.setItem("storeName",name)
                localStorage.setItem("companyReference", company)
                localStorage.setItem("companyAccountId", accountId)
                localStorage.setItem("companyId", companyId)
            
                document.location.reload(true)
            },

            purchaseToken(){
                const url = `${this.VUE_APP_INTERNATIONAL_URL}${this.flagSelected}/account/token-auth?tk=${this.tokenAuth}&destiny=client-space/purchase`
                window.open(url, '_blank')
            },

            lnkAddCompany(){
                const url = `${this.VUE_APP_MARKET_URL}${this.flagSelected}/account/token-auth?tk=${this.tokenAuth}&destiny=merchant/company/0`
                window.open(url, '_blank')
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            
            // OPEN LOGIN
            bus.on('open-login', (source) => {
                setTimeout(() => {
                    this.loginSource = source
                    this.loginActive = true
    
                    bus.emit("opened-login", true)
                }, 500);
            });


            // LOGOUT SIDEBAR
            bus.on('logout-mktplace', () => {
                this.logoutMarketPlace()
            })
        }
    }
</script>

<style src="@/assets/scss/_login.scss" lang="scss" scoped />