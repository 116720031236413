import apiMerchant from '@/config/apiMerchant.js'

import { handleError } from '@/services/errorHandler.js'

export default {
    data(){
        return {
            dataIntegrationLinks: [],

            errorsGet: {
                dataIntegrationLinks: false
            },

            modalLink: {
                show: false,
                load: false,
                data: {},
                link: ""
            }
        }
    },

    methods: {
        // INTEGRATION LINKS
        async loadIntegrationLinks(){
            this.sectionManage.load = true

            try {
                const responseLinks = await apiMerchant("/api/v2/plugin/link/filter")
                this.dataIntegrationLinks = responseLinks.data
                
                this.sectionManage.loaded.links = true

                setTimeout(() => {
                    this.sectionManage.load = false
                }, 500);
            }
            catch (error) {
                handleError(error, this.flagSelected)

                this.errorsGet.dataIntegrationLinks = JSON.stringify(error.response.status)
                // this.sectionManage.load = false
            }
        },

        filterLinks() {
            let a = '';
            let i = '';
            let txtValue = '';
            const inputList = document.getElementById("searchLinks");
            const filter = inputList.value.toUpperCase();
            const ul = document.getElementById("drop-list-links");
            const li = ul.getElementsByTagName("tr");
            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("td")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },

        openModalLink(value){
            this.modalLink.show = true
            this.modalLink.load = true
            this.modalLink.data = value
            
            this.modalLink.link = `${this.VUE_APP_PAY_URL}${this.flagSelected}/purchase/${this.modalLink.data.link_id}`

            setTimeout(() => {
                this.modalLink.load = false
            }, 500);
        },

        closeModalLink(){
            this.modalLink.show = false
            this.modalLink.load = false
            this.modalLink.data = {}
            this.modalLink.link = ""
        },

        updateModalLink(action){
            this.modalLink.load = true

            if ( action == "delete" ){
                apiMerchant.delete(`api/v2/plugin/link/${this.modalLink.data.link_id}`)
                .then(response => {
                    // this.dataIntegrationLinks = response.data
                    console.log(response)
                    console.clear()

                    this.loadIntegrationLinks()

                    setTimeout(() => {
                        this.$toast.success("Link de pagamento atualizado!")
                        this.modalLink.show = false
                        this.modalLink.load = false
                        this.modalLink.data = {}
                        this.modalLink.link = ""
                    }, 500);
                })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("Houve um erro")
                })
            }
        }
    }
}