<template>
    <div class="home">
        <div id="dne"></div>
        <Header :dataSource="page" />

        <section class="home-login">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-8 col-welcome">
                        <div class="glass">
                            <div class="welcome">
                                <img src="~@/assets/images/header/dne-pay-white.svg" alt="DNE Pay">
                            </div>
                            
                            <div class="description" v-html="getMyLanguage('Home','text.dne-pay')"></div>
                        </div>

                        <div class="download-app">
                            <div class="stores">
                                <a href="https://play.google.com/store/apps/details?id=com.dneecosystem.app" target="_blank">
                                    <img src="~@/assets/images/icons/app-dne-download-android.svg" alt="Google Play">
                                </a>
                                
                                <a href="https://apps.apple.com/pt/app/dne-ecosystem/id6466728703" target="_blank">
                                    <img src="~@/assets/images/icons/app-dne-download-ios.svg" alt="App Store">
                                </a>
                            </div>

                            <img src="~@/assets/images/content/qrcode-download-app.png" alt="Download DNE App" class="qrcode">
                        </div>
                    </div>

                    <div class="col-12 col-lg-4 col-box-login">
                        <div class="box-login">
                            <h2 class="title-secondary" v-if="loginRemember ===  false">
                                {{ getMyLanguage("Login", "login.access-account") }}
                            </h2>

                            <h2 class="title-secondary" v-if="loginRemember ===  true">
                                {{ getMyLanguage("Login", "login.remember-password") }}
                            </h2>
                            
                            <div class="LoginOne" v-if="loginOne === true">
                                <input type="text" class="form formEmailLogin" id="emailLogin"
                                    :placeholder="getMyLanguage('login','login.placeholder.login')"
                                    :class='{error: stepLogin.emailRegex == "error"}'
                                    v-model="sign.username"
                                    @keyup.enter="regexEmail('login')"
                                    @keyup="regexEmail()"
                                    @blur="regexEmail('check')">

                                <div class="enterPass">
                                    <input type="password" class="form formPassLogin"
                                        :placeholder="getMyLanguage('login','login.placeholder.password')"
                                        @keyup.enter="regexEmail('login')"
                                        v-if="!showPass"
                                        v-model="sign.password">

                                    <input type="text" class="form formPassLogin"
                                        :placeholder="getMyLanguage('login','login.placeholder.password')"
                                        @keyup.enter="regexEmail('login')"
                                        v-if="showPass"
                                        v-model="sign.password">

                                    <img src="@/assets/images/icons/no-view.svg" class="eyePass" v-if="!showPass" @click="showPass = true" />
                                    <img src="@/assets/images/icons/view.svg" class="eyePass" v-if="showPass" @click="showPass = false" />
                                </div>

                                <div class="btn-remember-password"
                                    v-on:click="loginResetPass"
                                    v-if="!$store.state.statusLogin">

                                    {{ getMyLanguage("Login", "login.remember-password") }}
                                </div>
                                
                                <div class="btn-primary btn-disabled"
                                    v-if="stepLogin.emailRegex == 'invalid' || stepLogin.emailRegex == 'error' || sign.username.length <= 5 || sign.password.length <= 7">
                                    
                                    {{ getMyLanguage("Login","login.login") }}
                                </div>

                                <div class="btn-primary"
                                    @click="loginPlatform()"
                                    v-if="stepLogin.emailRegex == 'validated' && !stepLogin.load && sign.username.length > 5 && sign.password.length > 7">

                                    {{ getMyLanguage("Login","login.login") }}
                                </div>

                                <div class="btn-primary btn-loading" v-if="stepLogin.load === true"></div>
                            </div>
                            
                            <div class="loginRemember" v-if="loginRemember && (!rememberSend || !rememberError)">
                                <input type="text" class="form formEmailLogin" id="emailRemember" required
                                    v-model="emailRemember.email"
                                    :placeholder='getMyLanguage("Login","login.remember-email-registered")'>

                                <button class="btn-primary" @click="rememberPass()" v-if="!emailRemember.load">
                                    {{ getMyLanguage("Login", "login.remember") }}
                                </button>
                                
                                <div class="btn-primary load" v-if="emailRemember.load"></div>
                            </div>

                            <div class="rememberPass rememberSuccess" v-if="!loginRemember && rememberSend">
                                <p>{{getMyLanguage("Login","login.remember-password.success")}}</p>
                            </div>
                            
                            <div class="rememberPass rememberError" v-if="loginRemember && rememberError">
                                <p>{{getMyLanguage("Login","login.remember-password.error")}}</p>
                            </div>


                            <div class="register">
                                <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/account/register`" target="_blank" class="btn-primary btn-register">
                                    {{ getMyLanguage("Login", "login.register-now") }}
                                </a>
                            </div>
                        </div>


                        <div class="download-app">
                            <div class="stores">
                                <a href="https://play.google.com/store/apps/details?id=com.dneecosystem.app" target="_blank">
                                    <img src="~@/assets/images/icons/app-dne-download-android.svg" alt="Google Play">
                                </a>
                                
                                <a href="https://apps.apple.com/pt/app/dne-ecosystem/id6466728703" target="_blank">
                                    <img src="~@/assets/images/icons/app-dne-download-ios.svg" alt="App Store">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>



<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Header"

    import apiPlatform from '@/config/apiPlatform.js'
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        components: {
            Header
        },

        data(){
            return{
                page: "home",

                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                VUE_APP_MARKET_URL: process.env.VUE_APP_MARKET_URL,
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
                VUE_APP_PAY_URL: process.env.VUE_APP_PAY_URL,
                VUE_APP_EXCHANGE_URL: process.env.VUE_APP_EXCHANGE_URL,

                logoutActive: false,
                loginOne: true,
                loginTwo: false,
                loginRemember: false,
                showPass: false,

                emailRemember: {
                    load: false,
                    email: ''
                },

                rememberSend: false,
                rememberError: false,
                dataAccount: [],

                sign: {
                    username: "",
                    password: ""
                },

                stepLogin: {
                    emailRegex: "",
                    load: false
                },

                validate: {
                    tooltipUsername: false,
                    tooltipPassword: false,
                },

                statusUserLogin: {
                    logged: this.$store.state.statusLogin
                },

                destiny: "client-space",

                settingsLogin: {
                    source: "login",
                    code: "",
                    language: "",
                    flagLanguage: "",
                    shippingCountry: "",
                    shippingFlag: "",
                    instrumentTicker: "",
                    tickerShort: ""
                }
            }
        },

        async mounted(){
            const urlParams = new URLSearchParams(window.location.search);
            
            if ( urlParams.size > 0 ){
                this.destiny = urlParams.get('destiny')
            }
        },

        methods: {
            regexEmail(type){
                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (regex.test(this.sign.username)) {
                    if ( type == "login" ){
                        this.stepLogin.emailRegex = "validated"
                        this.loginPlatform()
                    } else {
                        this.stepLogin.emailRegex = "validated"
                    }
                } else{
                    if ( type == "check" ){
                        this.stepLogin.emailRegex = "error"
                    } else {
                        this.stepLogin.emailRegex = "invalid"
                    }
                }
            },


            loginPlatform(){
                const loginPlatform = this.sign;
                this.stepLogin.load = true

                apiPlatform.post("/api/v1/platform/account/login", loginPlatform)
                .then(response => {
                    localStorage.setItem('accessToken', response.data.accessToken);

                    this.settingsLogin = response.data.profile

                    this.flagSelected = this.settingsLogin.account_language_alpha_2

                    localStorage.setItem('codeLanguage', this.settingsLogin.account_language_alpha_2)
                    localStorage.setItem('Language', this.settingsLogin.account_language_name)
                    localStorage.setItem('flagLanguage', this.settingsLogin.account_language_flag)

                    this.$store.commit('preLanguage', this.settingsLogin.account_language_alpha_2)
                    this.$store.commit('preLanguageFlag', this.settingsLogin.account_language_flag)
                    this.$store.commit('preLanguageName', this.settingsLogin.account_language_name)

                    // localStorage.setItem("shippingCountry", this.settingsLogin.profile_country_name) // alterar para país do endereço (indisponível no response)
                    // localStorage.setItem("shippingFlag", this.$store.state.allCountries.filter(ctry => ctry.Name == this.settingsLogin.profile_country_name)[0].Flag)
                    // localStorage.setItem("countryIsoAlpha3", this.settingsLogin.profile_country_iso_code_alpha_3)
                    
                    localStorage.setItem("instrumentTicker", this.settingsLogin.instrument_ticker)
                    localStorage.setItem("instrumentTickerShort", this.settingsLogin.asset_short)

                    // localStorage.setItem("shippingCountry", this.$store.state.allCountries.filter(ship => ship.IsoCode == this.settingsLogin.profile_country_iso_code)[0].Name)
                    // localStorage.setItem("shippingFlag", this.$store.state.allCountries.filter(ship => ship.IsoCode == this.settingsLogin.profile_country_iso_code)[0].Flag)
                    // localStorage.setItem("IsoCode", this.settingsLogin.profile_country_iso_code)

                    console.log(this.settingsLogin)

                    window.location.href = `/${this.flagSelected}/client-space/`
                })
                .catch(error => {
                    this.stepLogin.load = false

                    console.clear()
                    console.log(error);

                    $('.LoginOne').addClass('erroLogin');

                    setTimeout(() => {
                        $('.LoginOne').removeClass('erroLogin');
                    }, 6000);


                    if ( error.response.status == 400 ){
                        this.$toast.error(this.getMyLanguage("Login","login-password-error"));
                    } else{
                        this.$toast.error(this.getMyLanguage("Login","login-error-authentication"));
                    }
                })
            },


            // loginPlatform(){
            //     const loginPlatform = this.sign;
            //     this.stepLogin.load = true

            //     apiPlatform.post("/api/v1/platform/account/login", loginPlatform)
            //     .then(response => {
            //         localStorage.setItem('accessToken',response.data.accessToken);
            //         localStorage.setItem('codeLanguage',response.data.profile.account_language_alpha_2);
            //         localStorage.setItem('flagLanguage',response.data.profile.account_language_flag);
                    
            //         this.flagSelected = localStorage.getItem('codeLanguage')
            //         window.location.href = '/' + this.flagSelected + '/' + this.destiny
            //     })
            //     .catch(error => {
            //         this.stepLogin.load = false

            //         console.clear()
            //         console.log(error.message);

            //         $('.LoginOne').addClass('erroLogin');

            //         setTimeout(() => {
            //             $('.LoginOne').removeClass('erroLogin');
            //         }, 6000);

            //         let errorsLogin = JSON.stringify(error.response.status)
            //         this.loginErrors = errorsLogin

            //         if ( errorsLogin == 400 ){
            //             this.$toast.error(this.getMyLanguage("Login","login-password-error"));
            //         } else{
            //             this.$toast.error(this.getMyLanguage("Login","login-error-authentication"));
            //         }

            //         setTimeout(this.$toast.clear, 6000)
            //     })
            // },

            closeBoxLogin: function(){
                this.loginActive = false,
                this.loginOne = true,
                this.loginTwo = false,
                this.loginRemember = false
            },

            loginSMS: function(){
                this.loginOne = false,
                this.loginTwo = true
            },
            
            loginResetPass: function(){
                this.loginRemember = true,
                this.loginOne = false,
                this.loginTwo = false
            },


            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            rememberPass(){
                this.emailRemember.load = true
                
                apiPlatform.post("/api/v1/platform/account/password/reset", this.emailRemember)
                .then(response => {
                    this.registerId = response.data.id;
                    this.rememberSend = true
                    this.loginRemember = false

                })
                .catch(error => {
                    this.emailRemember.load = false
                    this.rememberError = true

                    console.clear()
                    console.log(error.message);
                })
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.login");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>


<style src="./custom-home.scss" lang="scss" scoped />

<style>
    .home-login .col-welcome .glass .description strong{
        color: #4aae57;
    }
</style>