<template>
    <div class="wallets">
        <div id="dne"></div>
        <Header :dataSource="page" />

        <section class="wallets-cont">
            
            <!-- WALLETS -->
            <div class="container balances">
                <div class="load load-200" v-if="!load"></div>
                
                <div class="row row-balances" v-if="showWallets">
                    <div class="col-12">
                        <div>
                            <h2 class="title-secondary">
                                {{ getMyLanguage("Wallets","title") }}
                            </h2>

                            <div v-for="(balance,indexWallet) in dataWallets" :key="indexWallet">
                                <div class="box box-balances" v-if="!showWallets">
                                    <div class="load"></div>
                                </div>
                                
                                <div class="box box-balances" v-if="showWallets">
                                    <table class="table-balances">
                                        <tr v-for="(accountWallet,indexList) in balance.source_wallets" :key="indexList" :class="accountWallet.accountWalletTypeCode">
                                            <td class="title">
                                                <div class="title-balance">
                                                    <i :class='balance.asset_short'>{{balance.asset_symbol}}</i>
                                                    <span>{{balance.asset_short}}<small>{{accountWallet.wallet_name}}</small></span>
                                                </div>
                                            </td>

                                            <td>
                                                <span class="balance-value" :class="balance.asset_short" v-if='balance.asset_short === "DNE"'>
                                                    <span>{{accountWallet.balance.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</span>
                                                    <small>&asymp; {{accountWallet.amount.toLocaleString("pt-PT", {style:"currency", currency:accountWallet.asset_short})}}</small>
                                                </span>
                                                
                                                <span class="balance-value" :class="balance.asset_short" v-else>
                                                    <span>{{accountWallet.balance.toLocaleString("pt-PT", {style:"currency", currency:balance.asset_short})}}</span>
                                                </span>
                                            </td>

                                            <td v-if="accountWallet.wallet_can_withdrawal">
                                                <div class="btn-balances btn-send" @click="this.modal.send = true"></div>
                                            </td>

                                            <td v-if="!accountWallet.wallet_can_withdrawal">
                                                <div class="btn-balances btn-send btn-disable"></div>
                                            </td>

                                            <td v-if='accountWallet.wallet_can_deposit && balance.asset_short === "DNE"'>
                                                <div class="btn-balances btn-receive" @click="(openReceive(balance.asset_short, accountWallet.account_wallet_type_code)), (modal.receive = true)"></div>
                                            </td>

                                            <td v-if='!accountWallet.wallet_can_deposit && balance.asset_short === "DNE"'>
                                                <div class="btn-balances btn-receive btn-disable"></div>
                                            </td>

                                            <td>
                                                <div class="btn-balances btn-history" @click="lnkHistory(accountWallet.account_wallet_type_code, accountWallet.account_wallet_id)"></div>
                                            </td>

                                            <div class="alert" v-if='accountWallet.account_wallet_type_code == "Stake"'>
                                                {{getMyLanguage("Wallets","box-balances.accountWalletTypeCode.stake.alert")}}
                                            </div>
                                        </tr>

                                        <tr class="total DNE" v-if='balance.asset_short === "DNE"'>
                                            <td colspan="6">
                                                <strong>
                                                    <small>{{getMyLanguage("Wallets","box-balances.total")}}</small>
                                                    <span>{{balance.quantity.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</span>
                                                </strong>
                                                <small>&asymp; {{balance.amount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</small>
                                            </td>
                                        </tr>
                                        
                                        <tr class="total" :class="balance.asset_short" v-else>
                                            <td colspan="6">
                                                {{getMyLanguage("Wallets","box-balances.total")}}<strong>{{balance.quantity.toLocaleString("pt-PT", {style:"currency", currency:balance.asset_short})}}</strong>
                                            </td>
                                        </tr>
                                    </table>
                                    <div class="overlayPreModal" v-if="modal.load"></div>


                                    <!-- BTS BALANCES -->
                                    <div class="bts-balances" v-if='balance.asset_short === "DNE"'>
                                        <div class="btn-primary btn-send" @click='this.modal.send = true'><span>{{getMyLanguage("Wallets","box-balances.bts.btn-send")}}</span></div>
                                        <div class="btn-primary btn-receive" @click='(openReceive("DNE", "spot")), (modal.receive = true)'><span>{{getMyLanguage("Wallets","box-balances.bts.btn-receive")}}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>


    <!-- MODAL SEND -->
    <div class="modal modal-send" v-if="modal.send">
        <div class="modal-cont">
            <div class="modal-close" @click="modal.send = false"></div>
            <div class="box">
                <div class="scroll-modal">
                    <h3 class="title-secondary">{{ getMyLanguage("DNEApp","download-app") }}</h3>

                    <div class="download-app">
                        <a href="https://play.google.com/store/apps/details?id=com.dneecosystem.app" target="_blank">
                            <img src="~@/assets/images/icons/app-dne-download-android.svg" alt="Google Play">
                        </a>
                        
                        <a href="https://apps.apple.com/pt/app/dne-ecosystem/id6466728703" target="_blank">
                            <img src="~@/assets/images/icons/app-dne-download-ios.svg" alt="App Store">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    <!-- MODAL RECEIVE -->
    <div class="modal modal-receive" v-if="modal.receive">
        <div class="modal-cont">
            <div class="modal-close" @click="modal.receive = false"></div>
            <div class="box">
                <div class="scroll-modal">

                    <div class="load extra-margin" v-if="flowReceive.load"></div>

                    <div class="receive-box" v-if="!flowReceive.load">
                        <h2 class="title-secondary">
                            {{getMyLanguage("receive","modal-receive.title")}} {{receiveModal.assetShort}}
                        </h2>

                        <div class="block-receive">
                            <div class="box-qrcode">
                                <qrcode-vue :value="qrCode.value" :size="qrCode.size" level="H" />
                            </div>

                            <div class="receive">
                                <table>
                                    <tr>
                                        <td colspan="2">
                                            <div class="copy" @click="copyReceive(receiveModal.walletAddress)">
                                                <strong>{{getMyLanguage("receive","modal-receive.address")}}: </strong>
                                                <small>{{receiveModal.walletAddress}}</small>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr v-if="receiveModal.walletAddressExtra">
                                        <td colspan="2">
                                            <div class="copy" @click="copyReceive(receiveModal.walletAddressExtra)">
                                                <strong>{{getMyLanguage("receive","modal-receive.extra")}}: </strong>
                                                <small>{{receiveModal.walletAddressExtra}}</small>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><strong>{{getMyLanguage("receive","modal-receive.walletType")}}: </strong></td>
                                        <td>{{receiveModal.accountWalletType}}</td>
                                    </tr>

                                    <tr>
                                        <td><strong>{{getMyLanguage("receive","modal-receive.asset")}}: </strong></td>
                                        <td><span>{{receiveModal.assetShort}}</span> <small class="asset-type">({{receiveModal.assetType}})</small></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import QrcodeVue from 'qrcode.vue'

    import apiInternational from '@/config/apiInternational.js';
    import apiPay from '@/config/apiPay.js';

    import moment from 'moment';

    import { handleError } from '@/services/errorHandler.js'
  
    export default {
        components: {
            Header,
            QrcodeVue
        },

        data () {
            return {
                VUE_APP_INTERNATIONAL_API: process.env.VUE_APP_INTERNATIONAL_API,
                page: "wallets",

                qrCode:{
                    value: "",
                    size: 180
                },

                flagSelected: "en",
                load: false,
                showWallets: false,
                showContent: false,

                dataWallets: [],

                modal: {
                    load: false,
                    send: false,
                    receive: false,
                },

                receiveModal:[],

                flowReceive:{
                    load: false
                },

                errorsGet: {
                    dataWallets: ""
                }
            }
        },

        async mounted(){                
            await apiPay.get('/api/v1/pay/wallet/balances')
            .then(response => {
                this.dataWallets = response.data
                
                setTimeout(() => {
                    this.showWallets = true
                    this.showContent = true
                    this.load = true
                }, 1000);
            })
            .catch(error => {
                console.error(error)
                this.errorsGet.dataWallets = error.response.status

                handleError(error, this.flagSelected)
            })
        },

        methods: {
            loginStatus(){
                if ( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null){
                    window.location.href = '/'
                }
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
                moment.locale(this.flagSelected);
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            },

            dateTimeShort(value) {
                const format = this.flagSelected === 'en' ? 'MM/DD' : 'DD/MM';
                return moment(value).format(format);
            },

            async openReceive(short, type){
                this.flowReceive.load = true

                const resumeReceiveModal = await apiInternational.get(`/api/v1/pay/wallet/deposit/${short}/${type}`)
                this.receiveModal = resumeReceiveModal.data

                this.qrCode.value = this.receiveModal.walletAddress

                this.flowReceive.load = false
            },

            async copyReceive(urlShare) {
                try {
                await navigator.clipboard.writeText(urlShare);
                    this.$toast.success(this.getMyLanguage("receive", "modal-receive.copied"));
                }
                
                catch($e) {
                    this.$toast.success("Not copied");
                }
            },

            lnkHistory(type,id){
                window.location.href = `/${this.flagSelected}/client-space/transactions/${type}/${id}`
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
        
        created () {
            document.title = `${this.getMyLanguage("Wallets","title")} | DNE Pay`;
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-wallets.scss" lang="scss" scoped />