<template>
    <div class="row-menu" v-if="showContent">
        <div class="openMenuMobile" @click="showMenuMobile = true"></div>

        <div class="menu" :class="{active: showMenuMobile}">
            <ul class="menu-pay" :class="{customer: isMerchant}">
                
                <div class="legend" v-if="isMerchant">
                    <span>{{ getMyLanguage("ClientSpace","menu-client.legend.client") }}</span>
                </div>

                <li v-if="$store.state.statusLogin">
                    <a :href="`/${flagSelected}/client-space/wallets`" class="btn-wallets">
                        <span>{{ getMyLanguage("Wallets","title") }}</span>
                    </a>
                </li>

                <li v-if="$store.state.statusLogin">
                    <a :href="`/${flagSelected}/client-space/transactions`" class="btn-transactions">
                        <span>{{ getMyLanguage("Transactions","title") }}</span>
                    </a>
                </li>
            </ul>

            <ul class="menu-pay merchant" :class="{merchant: isMerchant}" v-if="isMerchant && $store.state.statusLogin">
                <div class="legend">
                    <span>{{ getMyLanguage("ClientSpace","menu-client.legend.merchant") }}</span>
                </div>

                <li>
                    <a :href="`/${flagSelected}/merchant/sales`" class="btn-sales">
                        <span>{{ getMyLanguage("Sales","title") }}</span>
                    </a>
                </li>

                <li>
                    <a :href="`/${flagSelected}/merchant/plugin`" class="btn-plugin">
                        <span>Plugin</span>
                    </a>
                </li>

                <li>
                    <div @click="lnkCompany()" class="btn-company">
                        <span>{{ getMyLanguage("Company","title") }}</span>
                    </div>
                </li>
            </ul>

            <ul class="menu-pay">
                <li>
                    <div class="btn-purchase-token" @click="purchaseToken()">
                        <span>Comprar DNE Token</span>
                    </div>
                </li>

                <li>
                    <a :href="`/${flagSelected}/client-space/dne-app`" class="btn-app">
                        <span>DNE App</span>
                    </a>
                </li>
            </ul>

            <div class="entities showEntities">
                <div class="entity hasAnchors" :class="{opened: showMenu}">
                    <div class="openMenu" @click="showMenu = !showMenu"></div>
                    <i>{{ getMyLanguage("page-active","you-are-in") }}</i>
                    <h2>DNE <span>P</span>ay</h2>
                </div>

                <ul class="menu-anchors" :class="{active: showMenu}" v-if="flagSelected">
                    <li v-if="$store.state.statusLogin">
                        <a :href="`/${flagSelected}/client-space/wallets`" class="btn-wallets">
                            <span>{{ getMyLanguage("Wallets","title") }}</span>
                        </a>
                    </li>

                    <li v-if="$store.state.statusLogin">
                        <a :href="`/${flagSelected}/client-space/transactions`" class="btn-transactions">
                            <span>{{ getMyLanguage("Transactions","title") }}</span>
                        </a>
                    </li>

                    <li v-if="isMerchant && $store.state.statusLogin">
                        <a :href="`/${flagSelected}/merchant/sales`" class="btn-sales">
                            <span>{{ getMyLanguage("Sales","title") }}</span>
                        </a>
                    </li>

                    <li v-if="isMerchant && $store.state.statusLogin">
                        <a :href="`/${flagSelected}/merchant/plugin`" class="btn-plugin">
                            <span>Plugin</span>
                        </a>
                    </li>

                    <li v-if="isMerchant && $store.state.statusLogin">
                        <div @click="lnkCompany()" class="btn-company">
                            <span>{{ getMyLanguage("Company","title") }}</span>
                        </div>
                    </li>

                    <li>
                        <div class="btn-purchase-token" @click="purchaseToken()">
                            <span>Comprar DNE Token</span>
                        </div>
                    </li>

                    <li>
                        <a :href="`/${flagSelected}/client-space/dne-app`" class="btn-app">
                            <span>DNE App</span>
                        </a>
                    </li>
                </ul>
                
                <ul class="menu-entities">
                    <div class="entity get-to-know">
                        <h2>{{ getMyLanguage("client-space","client-space.home.title") }}</h2>
                    </div>

                    <li>
                        <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}`" v-if="!$store.state.statusLogin">
                            <h2>DNE <span>E</span>cosystem</h2>
                        </a>
                        
                        <h2 v-if="$store.state.statusLogin" @click="lnkEcosystem()">DNE <span>E</span>cosystem</h2>
                    </li>

                    <li>
                        <a :href="`${VUE_APP_INTERNATIONAL_URL}${flagSelected}`" v-if="!$store.state.statusLogin">
                            <h2>DNE <span>T</span>oken</h2>
                        </a>
                        
                        <h2 @click="lnkToken()" v-if="$store.state.statusLogin">DNE <span>T</span>oken</h2>
                    </li>
                    
                    <li>
                        <a :href="`${VUE_APP_MARKET_URL}${flagSelected}`" v-if="!$store.state.statusLogin">
                            <h2>DNE <span>M</span>arket</h2>
                        </a>
                    
                        <h2 @click="lnkMarket()" v-if="$store.state.statusLogin">DNE <span>M</span>arket</h2>
                    </li>

                    <li>
                        <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/exchange`">
                            <h2>DNE <span>E</span>xchange</h2>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="closeMenuMobile" v-if="showMenuMobile" @click="showMenuMobile = false"></div>
</template>


<script>
    document.addEventListener("click", (event) => {
        const link = event.target.closest("a"); 
        if (!link) return;

        const linkHref = link.href;
        const currentHost = window.location.host; // Actual domain

        // Check external link
        if (linkHref && !linkHref.includes(currentHost)) {
            event.preventDefault();

            // Add hash #redirect before exit
            const newUrl = linkHref.includes("#") ? `${linkHref}&redirect` : `${linkHref}#redirect`;

            // Redirect manually
            setTimeout(() => {
                window.location.href = newUrl;
            }, 100);
        }
    });


    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        props: {
            dataSource: String
        },

        data(){
            return {
                flagSelected: "en",
                showContent: false,

                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                VUE_APP_MARKET_URL: process.env.VUE_APP_MARKET_URL,
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
                VUE_APP_PAY_URL: process.env.VUE_APP_PAY_URL,
                VUE_APP_EXCHANGE_URL: process.env.VUE_APP_EXCHANGE_URL,

                tokenAuth: localStorage.getItem('accessToken'),

                hasToken: "",
                token: "",
                newtk: "",
                storeRef: "",

                isMerchant: !!localStorage.getItem("isMerchant"),


                showMenuMobile: false,
                showMenu: false,
            }
        },

        mounted() {
            setTimeout(() => {
                this.checkLocalStorage();
                window.addEventListener("storage", this.handleStorageChange);

                this.showContent = true
            }, 500);
        },
        
        beforeUnmount() {
            window.removeEventListener("storage", this.handleStorageChange);
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
                
                if ( !this.flagSelected ){
                    this.flagSelected = "en"
                }
            },

            lnkEcosystem(){
                window.location.href = `${this.VUE_APP_PLATFORM_URL}${this.flagSelected}/account/token-auth?tk=${this.tokenAuth}&destiny=client-space`
            },

            lnkMarket(){
                window.location.href = `${this.VUE_APP_MARKET_URL}${this.flagSelected}/account/token-auth?tk=${this.tokenAuth}&destiny=`
            },
            
            lnkToken(){
                window.location.href = `${this.VUE_APP_INTERNATIONAL_URL}${this.flagSelected}/account/token-auth?tk=${this.tokenAuth}&destiny=client-space`
            },
            
            lnkExchange(){
                window.location.href = `${this.VUE_APP_EXCHANGE_URL}${this.flagSelected}/account/token-auth?tk=${this.tokenAuth}&destiny=`
            },

            purchaseToken(){
                const url = `${this.VUE_APP_INTERNATIONAL_URL}${this.flagSelected}/account/token-auth?tk=${this.tokenAuth}&destiny=client-space/purchase`
                window.open(url, '_blank')
            },

            lnkCompany(){
                this.storeRef = localStorage.getItem("storeReference")
                const url = `${this.VUE_APP_MARKET_URL}${this.flagSelected}/account/token-auth?tk=${this.tokenAuth}&destiny=merchant/company/${this.storeRef}`
                window.open(url, '_blank')
            },
            
            checkLocalStorage() {
                this.isMerchant = !!localStorage.getItem("isMerchant");
            },

            handleStorageChange(event) {
                setTimeout(() => {
                    if (event.key === "isMerchant") {
                        this.checkLocalStorage();
                    }
                }, 500);
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected()
                }
            },
            isMerchant(newValue) {
                console.log("isMerchant atualizado:", newValue);
            }
        }
    }
</script>


<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_dneElements.scss';

    .row-menu{
        @media (min-width: 1200px){
            display: flex;
            justify-content: center;
        }

        *{
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }

    .openMenuMobile{
        @media (min-width: 1200px){
            display: none;
        }
        
        @media (max-width: 1200px){
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: url(~@/assets/images/icons/menuMobile.svg) no-repeat center $color-text-tertiary;
            background-size: 16px auto;
            border: 1px solid #eee;
            transition: .3s;
            cursor: pointer;

            &:hover{
                background-color: #e5e5e5;
            }
        }
    }

    .menu{
        @media (min-width: 1200px){
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @media (max-width: 330px){
            padding: 2.5rem 1rem;
        }

        @media (min-width: 330px) and (max-width: 1200px){
            padding: 3rem 2rem;
        }

        @media (max-width: 1200px){
            @include scrollbars(8px, $color-secondary, #ddd);
            overflow-y: auto;
            position: fixed;
            z-index: 9999;
            top: 0%;
            left: -360px;
            width: calc(100% - 40px);
            height: 100vh;
            max-width: 360px;
            background: #f9f9f9;

            &.active{
                transition: 1s;
                left: 0;

                .entity{
                    @media (max-width: 1200px){
                        display: block;
                    }
                }
            }
        }

        h2{
            padding: 0 1rem;
            margin: 0;
            white-space: nowrap;
            transition: .3s;
            cursor: pointer;

            @media (max-width: 1200px){
                font-size: 16px;
                line-height: 40px;
            }

            @media (min-width: 1200px){
                font-size: 16px;
                line-height: 36px;
                border-radius: 12px;
            }

            span{
                &::after{
                    height: 3px;
                }
            }
        }
        

        // MENU PAY
        .menu-pay{
            @media (min-width: 1200px){
                display: none;
                // height: 40px;
                // display: flex;
                // flex-wrap: wrap;
            }

            &.hideMenu{
                display: none !important;
            }

            &.customer, &.merchant{
                position: relative;

                @media (max-width: 1200px){
                    margin-bottom: 2rem;
                }

                @media (min-width: 1200px){
                    margin-right: 1rem;
                }
            }


            &.customer{
                li{
                    a, div{
                        background: rgba($color: $color-primary, $alpha: 0.15);
                    }
                }

                .legend{
                    color: $color-primary;

                    &::after{
                        background: rgba($color: $color-primary, $alpha: 0.2);
                    }
                }
            }
            
            &.merchant{
                li{
                    a, div{
                        background: rgba($color: $color-secondary, $alpha: 0.15);
                    }
                }

                .legend{
                    color: $color-secondary;

                    &::after{
                        background: rgba($color: $color-secondary, $alpha: 0.3);
                    }
                }
            }

            .legend{
                display: flex;
                justify-content: center;
                width: 100%;

                @media (max-width: 1200px){
                    font-size: 12px;
                    line-height: 12px;
                    margin-bottom: 1rem;
                }
                
                @media (min-width: 1200px){
                    position: absolute;
                    left: 0;
                    top: -18px;
                    font-size: 12px;
                    line-height: 12px;
                }

                span{
                    position: relative;
                    z-index: 20;
                    display: block;
                    padding: 0 5px;
                    background: #f9f9f9;
                }

                &::after{
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 6px;
                    width: 100%;
                    height: 1px;
                }
            }

    
            li{
                padding: 0 3px;
    
                a, div{
                    font-size: 14px;
                    line-height: 40px;
                    color: #555;
                    background: #EDEDED;
                    display: flex;
                    align-items: center;
                    padding: 0 10px;
                    border-radius: 12px;
                    white-space: nowrap;
                    transition: .3s;
                    cursor: pointer;
    
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;

                    @media (max-width: 1200px){
                        margin-bottom: .5rem;
                    }
    
                    @media (min-width: 1200px){                        
                        &:hover, &.active{
                            background: #EDEDED;
                            padding: 0 10px;
                        }
                    }

                    &::before{
                        content: "";
                        display: block;
                        width: 18px;
                        height: 18px;
                        margin-right: 10px;
                        background: #000;
                    }

                    &.btn-wallets{
                        &::before{
                            background: url(~@/assets/images/menu/wallets.svg);
                        }
                    }
                    
                    &.btn-transactions{
                        &::before{
                            background: url(~@/assets/images/menu/transactions.svg);
                        }
                    }

                    &.btn-sales{
                        &::before{
                            background: url(~@/assets/images/menu/sales.svg);
                        }
                    }
                    
                    &.btn-plugin{
                        &::before{
                            background: url(~@/assets/images/menu/plugin.svg);
                        }
                    }
                    
                    &.btn-company{
                        &::before{
                            background: url(~@/assets/images/menu/company.svg);
                        }
                    }
                    
                    &.btn-purchase-token{
                        &::before{
                            background: url(~@/assets/images/icons/btn-box-purchase.svg);
                        }
                    }

                    &.btn-app{
                        &::before{
                            background: url(~@/assets/images/menu/app.svg);
                        }
                    }
                }
            }
        }


        .entities{
            @media (max-width: 1200px){
                margin-top: 3rem;
            }

            // @media (min-width: 1200px){
            //     display: none;
            // }

            &.showEntities{
                @media (min-width: 1200px){
                    display: flex !important;
                }
            }

            .entity{
                position: relative;
    
                @media (min-width: 1200px){
                    margin-right: 3rem;
                }
    
                @media (max-width: 1200px){
                    z-index: 20;
                }

                .openMenu{
                    @media (max-width: 1200px){
                        display: none;
                    }

                    @media (min-width: 1200px){
                        display: block;
                        position: absolute;
                        z-index: 20;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                    }
                }
    
                i{
                    display: block;
                    font-style: normal;
                    font-size: 10px;
                    white-space: nowrap;
                    position: absolute;
                    z-index: 10;
    
                    @media (max-width: 1200px){
                        @include shadown;
                        line-height: 20px;
                        color: $color-text-tertiary;
                        padding: 0 10px;
                        border-radius: 12px;
                        background: #aaa;
                        top: -17px;
                        left: .5rem;
                    }
    
                    @media (min-width: 1200px){
                        top: -15px;
                        left: 1rem;
                        line-height: 12px;
                    }
                }
    
                h2{
                    background: #eee;
                    border-radius: 12px;
    
                    @media (max-width: 1200px){
                        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
                    }
                }

                &.hasAnchors{
                    @media (min-width: 1200px){
                        h2{
                            position: relative;
                            padding-right: 2.5rem;

                            &::after{
                                content: '';
                                display: block;
                                position: absolute;
                                right: 1rem;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 0;
                                height: 0;
                                border-left: 6px solid transparent;
                                border-right: 6px solid transparent;
                                border-top: 6px solid $color-primary;
                            }
                        }

                        &:hover, &.opened{
                            h2{
                                background-color: #ddd;
                            }
                        }
                    }
                }
            }


            // MENU ANCHORS
            .hasAnchors + .menu-anchors.active{
                @media (min-width: 1200px){
                    display: block;
                }
            }
            
            .hasAnchors + .menu-anchors{
                @media (max-width: 1200px){
                    display: block;
                }
            }

            .menu-anchors{
                display: none;

                @media (max-width: 1200px){
                    display: none !important;
                    // @include shadown;
                    // padding: calc(1rem + 12px) 1.5rem 1rem 1.5rem;
                    // margin: -12px auto 1rem;
                    // width: calc(100% - 1rem);
                    // border-radius: 12px;
                    // background: #f5f5f5;
                }

                @media (min-width: 1200px){
                    @include shadown;
                    background: $color-text-tertiary;
                    position: absolute;
                    left: 0;
                    top: calc(100% + 10px);
                    padding: 1rem;
                    border-radius: 12px;
                    animation: showMenuAnchors .3s alternate;

                    &.active{
                        display: block;
                    }

                    @keyframes showMenuAnchors {
                        0% {opacity: 0; top: calc(100% + 20px);}
                        100% {opacity: 1; top: calc(100% + 10px);}
                    }
                }

                li{
                    a, div{
                        font-size: 14px;
                        line-height: 36px;
                        color: $color-primary;
                        padding: 0 45px 0 40px;
                        white-space: nowrap;
                        border-bottom: 1px solid $color-tertiary;
                        position: relative;

                        &::before, &::after{
                            content: '';
                            display: block;
                            position: absolute;
                            transform: translateY(-50%);
                        }

                        &::before{
                            left: 5px;
                            top: 50%;
                            width: 24px;
                            height: 24px;
                            border-radius: 3px;
                            background-size: 18px auto !important;
                        }

                        &.btn-wallets{
                            &::before{
                                background: url(~@/assets/images/menu/wallets.svg) no-repeat center;
                            }
                        }

                        &.btn-transactions{
                            &::before{
                                background: url(~@/assets/images/menu/transactions.svg) no-repeat center;
                            }
                        }

                        &.btn-sales{
                            &::before{
                                background: url(~@/assets/images/menu/sales.svg) no-repeat center;
                            }
                        }

                        &.btn-plugin{
                            &::before{
                                background: url(~@/assets/images/menu/plugin.svg) no-repeat center;
                            }
                        }

                        &.btn-company{
                            &::before{
                                background: url(~@/assets/images/menu/company.svg) no-repeat center;
                            }
                        }
                        
                        &.btn-purchase-token{
                            &::before{
                                background: url(~@/assets/images/icons/btn-box-purchase.svg) no-repeat center;
                            }
                        }

                        &.btn-app{
                            &::before{
                                background: url(~@/assets/images/menu/app.svg) no-repeat center;
                            }
                        }


                        &::after{
                            right: 5px;
                            top: 50%;
                            width: 6px;
                            height: 10px;
                            background: url(~@/assets/images/icons/arrow-btn-menu-mobile.svg) no-repeat center;
                            background-size: auto 100%;
                            transition: .3s;
                        }

                        &:hover{
                            &::after{
                                right: 0;
                            }
                        }
                    }

                    &:last-child{
                        a, div{
                            border-bottom: none;
                        }
                    }
                }
            }
    
    
            // MENU ENTITIES
            .menu-entities{
                @media (max-width: 1200px){
                    @include shadown;
                    position: relative;
                    top: -1rem;
                    padding: 2rem 1rem 1rem 1rem;
                    margin-bottom: 1rem;
                    width: 100%;
                    border-radius: 12px;
                    background: #f5f5f5;
    
                    .get-to-know{
                        position: absolute;
                        top: 0;
                        left: -0.5rem;
                        width: calc(100% + 1rem);
                    }
    
                    li{
                        a, > h2{
                            padding: 0 2.5rem 0 38px;
                            white-space: nowrap;
                            border-bottom: 1px solid $color-tertiary;
                            position: relative;
    
                            &::before, &::after{
                                content: '';
                                display: block;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                            }
    
                            &::before{
                                left: 0;
                                width: 28px;
                                height: 22px;
                                border-right: 1px solid #ccc;
                                background: url(~@/assets/images/icons/dne.svg) no-repeat left center;
                                background-size: auto 22px;
                            }
    
                            &::after{
                                width: 6px;
                                height: 10px;
                                background: url(~@/assets/images/icons/arrow-btn-menu-mobile.svg) no-repeat center;
                                background-size: auto 100%;
                                right: 10px;
                            }
    
                            h2{
                                padding: 0;
                            }
    
                            &:hover{
                                &::after{
                                    right: 5px;
                                }
                            }
                        }
    
                        &:last-child{
                            a{
                                border-bottom: none;
                            }
                        }
                    }
                }
    
                @media (min-width: 1200px){
                    display: flex;
                    flex-wrap: nowrap;
    
                    h2{
                        &:hover{
                            background: #efefef;
                        }
                    }
                    
                    .get-to-know{
                        display: none;
                    }
                }
    
                .lnk-ico{
                    @media (max-width: 1200px){
                        background: $color-secondary;
                        border-radius: 12px;
                        margin: .5rem 0;
                        text-align: center;
    
                        &::before, &::after{
                            display: none;
                        }
                    }
    
                    @media (min-width: 1200px){
                        &:hover{
                            h2{
                                background: $color-primary;
    
                                span{
                                    &::after{
                                        background: $color-secondary;
                                    }
                                }
                            }
                        }
                    }
    
                    h2{
                        background: $color-secondary;
                        color: $color-text-tertiary;
                        
                        span{
                            color: $color-text-tertiary;
                            
                            &::after{
                                background: $color-primary;
                                transition: .3s;
                            }
                        }
                    }
                }
            }
        }
    }

    .closeMenuMobile{
        @media (max-width: 1200px){
            position: fixed;
            z-index: 9990;
            right: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            background-size: 24px auto !important;
            background: url("~@/assets/images/icons/close-white.svg") no-repeat rgba($color: #000000, $alpha: 0.75);
            cursor: pointer;
        }

        @media (max-width: 576px){
            animation: closeOverlaySmall 1.2s alternate;
            background-position: calc(100% - 8px) 1rem;
        }
        
        @media (min-width: 576px){
            animation: closeOverlay 1.2s alternate;
            background-position: 380px 1rem;
        }
    }

    @keyframes closeOverlaySmall {
        0% {background-position: calc(100% + 40px) 1rem}
        75% {background-position: calc(100% + 40px) 1rem}
        100% {background-position: calc(100% - 8px) 1rem;}
    }
    
    @keyframes closeOverlay {
        0% {background-position: -40px 1rem}
        75% {background-position: -40px 1rem}
        100% {background-position: 380px 1rem;}
    }
</style>