<template>
    <div class="wallets">
        <div id="dne"></div>
        <Header :dataSource="page" />

        <section class="wallets-cont">
            
            <!-- WALLETS -->
            <div class="container balances">
                <div class="row row-balances">
                    <div class="col-12">
                        <div>
                            <h2 class="title-secondary">
                                {{ getMyLanguage("Transactions","title") }}
                                <small>{{$route.params.walletType}}</small>
                            </h2>

                            <div class="box box-balances">
                                <div class="load" v-if="!showWallets"></div>

                                <table class="table-activities" v-if="showWallets">
                                    <tr class="tr" v-for="(walletHistory, index) in dataHistory" :key="index" @click="detailsHistory(index)">
                                        <td class="td date">{{ dateTimeShort(walletHistory.operation_date) }}</td>
                                        <td class="td description">
                                            <strong v-if='walletHistory.operation_type === "Fee"'>{{getMyLanguage("history","modal-history.transactionType." + walletHistory.transaction_type)}} {{getMyLanguage("history","modal-history.operationType." + walletHistory.operation_type)}}</strong>
                                            <strong v-if='walletHistory.operation_type != "Fee"'>{{getMyLanguage("history","modal-history.operationType." + walletHistory.operation_type)}} {{walletHistory.operation_account_name}}</strong>
                                            <small>{{ walletHistory.operation_description }}</small>
                                            
                                            <small v-if="walletHistory.stakeEnd != null">{{getMyLanguage("history","modal-history.available-in")}} {{ dateTime(walletHistory.stakeEnd)}}</small>
                                        </td>
                                        <td class="td values" :class='{output: walletHistory.operation_amount < 0}'>
                                            <span :class="walletHistory.asset_short">
                                                {{ walletHistory.operation_amount.toLocaleString("pt-PT", {maximumFractionDigits: 4}) }}
                                            </span>

                                            <strong class="balance" :class="walletHistory.asset_short">
                                                {{ walletHistory.operation_price.toLocaleString("pt-PT", {style:"currency", currency:walletHistory.asset_short_price}) }}
                                            </strong>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>



                <!-- MODAL HISTORY -->
                <div class="modal modal-history" v-if="modal.history">
                    <div class="modal-cont">
                        <div class="modal-close" @click="modal.history = false"></div>

                        <div class="box-history">
                            <table>
                                <tr v-if='dataHistoryDetails.operation_description'>
                                    <td><strong>{{getMyLanguage("history","modal-history.details.name")}}</strong></td>
                                    <td>{{dataHistoryDetails.operation_description}}</td>
                                </tr>

                                <tr v-if="dataHistoryDetails.operation_date">
                                    <td><strong>{{getMyLanguage("history","modal-history.details.date")}}</strong></td>
                                    <td>{{dateTime(dataHistoryDetails.operation_date)}}</td>
                                </tr>

                                <tr v-if="dataHistoryDetails.transaction_type">
                                    <td><strong>{{getMyLanguage("history","modal-history.details.transaction")}}</strong></td>
                                    <td>{{getMyLanguage("history","modal-history.transactionType." + dataHistoryDetails.transaction_type)}}</td>
                                </tr>

                                <tr v-if="dataHistoryDetails.operation_type">
                                    <td><strong>{{getMyLanguage("history","modal-history.details.operation")}}</strong></td>
                                    <td>{{getMyLanguage("history","modal-history.operationType." + dataHistoryDetails.operation_type)}}</td>
                                </tr>

                                <tr>
                                    <td>
                                        <strong>{{getMyLanguage("history","modal-history.details.total")}}</strong>
                                    </td>
                                    <td class="details-price">
                                        <span class="dne">
                                            {{ dataHistoryDetails.operation_amount.toLocaleString("pt-PT", {maximumFractionDigits: 4}) }}
                                        </span>

                                        <span class="price">
                                            {{ dataHistoryDetails.operation_price.toLocaleString("pt-PT", {style:"currency", currency:dataHistoryDetails.asset_short_price}) }}
                                        </span>
                                    </td>
                                </tr>

                                <tr v-if="dataHistoryDetails.stakeEnd">
                                    <td>
                                        {{getMyLanguage("history","modal-history.details.stake")}}:
                                    </td>
                                    <td>
                                        {{ dateTime(dataHistoryDetails.stakeEnd)}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiPay from '@/config/apiPay.js';

    import moment from 'moment';

    import { handleError } from '@/services/errorHandler.js'
  
    export default {
        components: {
            Header,
        },

        data () {
            return {
                VUE_APP_INTERNATIONAL_API: process.env.VUE_APP_INTERNATIONAL_API,

                page: "history",

                flagSelected: "en",
                load: false,
                showWallets: false,
                showContent: false,
                dataWallets: [],

                modal: {
                    load: false,
                    history: false
                },

                dataHistory: [],
                dataHistoryDetails: {
                    show: false
                },

                errorsGet: {
                    dataWallets: ""
                },
            }
        },

        async mounted(){
            await apiPay.get(`/api/v1/pay/tx/txs/${this.$route.params.walletId}`)
            .then(response => {
                this.dataHistory = response.data

                setTimeout(() => {
                    this.showWallets = true
                }, 1000);
            })
            .catch(error => {
                console.error(error)
                this.errorsGet.dataHistory = error.response.status

                handleError(error, this.flagSelected)
            })
        },

        methods: {
            loginStatus(){
                if ( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null){
                    window.location.href = '/'
                }
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
                moment.locale(this.flagSelected);

                document.title = `${this.$route.params.walletType.toUpperCase()} | ${this.getMyLanguage("Transactions","title")} | DNE Pay`
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            goTop(){
                window.location.hash="anchor-content";
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            },

            dateTimeShort(value) {
                const format = this.flagSelected === 'en' ? 'MM/DD' : 'DD/MM';
                return moment(value).format(format);
            },

            detailsHistory(value){
                this.modal.load = false
                this.modal.history = true

                this.dataHistoryDetails = this.dataHistory[value]
                this.dataHistoryDetails.show = true
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
        
        created () {
            document.title = `${this.$route.params.walletType.toUpperCase()} | ${this.getMyLanguage("Transactions","title")} | DNE Pay`;
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-transactions.scss" lang="scss" scoped />