<template>
    <div class="wallets">
        <div id="dne"></div>
        <Header :dataSource="page" />

        <section class="wallets-cont">
            
            <!-- WALLETS -->
            <div class="container balances">
                <div class="load load-200" v-if="!load"></div>
                
                <div class="row row-balances" v-if="showWallets">
                    <div class="col-12">
                        <div>
                            <h2 class="title-secondary">
                                {{ getMyLanguage("Transactions","title") }}
                            </h2>

                            <div class="wallets-transactions" v-for="(balance, indexWallet) in dataWallets" :key="indexWallet">

                                <div class="wallet"
                                    v-for="(wallet, indexWallet) in balance.source_wallets"
                                    :key="indexWallet"
                                    @click="lnkHistory(wallet.account_wallet_type_code, wallet.account_wallet_id)">

                                    <div class="title">
                                        <i :class='balance.asset_short'>{{balance.asset_symbol}}</i>
                                        <span>
                                            <strong>{{balance.asset_short}}</strong>
                                            <small>{{wallet.wallet_name}}</small>
                                        </span>
                                    </div>
                                    
                                    <p class="obs" v-if="wallet.account_wallet_type_code == 'spot'">{{ getMyLanguage("Transactions","msg.spot") }}</p>
                                    <p class="obs" v-if="wallet.account_wallet_type_code == 'stake'">{{ getMyLanguage("Transactions","msg.stake") }}</p>
                                    <p class="obs" v-if="wallet.account_wallet_type_code == 'payment'">{{ getMyLanguage("Transactions","msg.payment") }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import getMyContentLanguage from "@/services/contentLanguage.js"

    import apiPay from '@/config/apiPay.js';

    import moment from 'moment';

    import { handleError } from '@/services/errorHandler.js'
  
    export default {
        components: {
            Header
        },

        data () {
            return {
                VUE_APP_INTERNATIONAL_API: process.env.VUE_APP_INTERNATIONAL_API,

                page: "transactions",

                flagSelected: "en",
                load: false,
                showWallets: false,
                showContent: false,

                dataWallets: [],

                errorsGet: {
                    dataWallets: ""
                }
            }
        },

        async mounted(){
            await apiPay.get('/api/v1/pay/wallet/balances')
            .then(response => {
                this.dataWallets = response.data
                
                setTimeout(() => {
                    this.showWallets = true
                    this.showContent = true
                    this.load = true
                }, 1000);
            })
            .catch(error => {
                console.error(error)
                this.errorsGet.dataWallets = error.response.status

                handleError(error, this.flagSelected)
            })
        },

        methods: {
            loginStatus(){
                if ( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null){
                    window.location.href = '/'
                }
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
                moment.locale(this.flagSelected);
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            },

            dateTimeShort(value) {
                const format = this.flagSelected === 'en' ? 'MM/DD' : 'DD/MM';
                return moment(value).format(format);
            },

            lnkHistory(type, id){
                window.location.href = `/${this.flagSelected}/client-space/transactions/${type}/${id}`
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
        
        created () {
            document.title = `${this.getMyLanguage("Transactions","title")} | DNE Pay`;
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-transactions.scss" lang="scss" scoped />