import apiMerchant from '@/config/apiMerchant.js'

import { handleError } from '@/services/errorHandler.js'

export default {
    data(){
        return {
            dataLogs: [],

            errorsGet: {
                dataLogs: false
            },

            logSidebar: {
                show: false,
                data: {}
            },

            resend: {
                load: false,

            }
        }
    },

    methods: {
        // LOGS
        async loadLogs(){
            this.sectionManage.load = true

            try {
                const responseLogs = await apiMerchant("/api/v2/plugin/webhook/logs")
                this.dataLogs = responseLogs.data
                
                this.sectionManage.loaded.logs = true

                setTimeout(() => {
                    this.sectionManage.load = false
                }, 500);
            }
            catch (error) {
                handleError(error, this.flagSelected)

                this.errorsGet.dataLogs = JSON.stringify(error.response.status)
                // this.sectionManage.load = false
            }
        },

        filterLogs() {
            let a = '';
            let i = '';
            let txtValue = '';
            const inputList = document.getElementById("searchLogs");
            const filter = inputList.value.toUpperCase();
            const ul = document.getElementById("drop-list-logs");
            const li = ul.getElementsByTagName("tr");
            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("td")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },


        // LOGS > SIDEBAR
        openLog(value){
            this.logSidebar.data = value
            this.logSidebar.show = true

            document.body.classList.add('body-no-scroll')
        },

        closeLog(){
            this.logSidebar.data = ""
            this.logSidebar.show = false

            document.body.classList.remove('body-no-scroll')
        },

        resendLog(){
            this.resend.load = true

            setTimeout(() => {
                this.resend.load = false
            }, 3000);
        },

        convertCode(value){
            return JSON.parse(value)
        }
    }
}